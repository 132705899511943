<template>
  <div>
    <i18n-t
      v-if="type === 'actionLiveness'"
      keypath="notifications.body.biometricTest"
      tag="p"
      class="text"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else
      keypath="notifications.body.verificationNeed"
      tag="p"
      class="text"
    >
      <template #level>
        <b>{{ level }}</b>
      </template>
    </i18n-t>
    <StButton
      class="button"
      size="s"
      :label="t('verification.passVerification')"
      :to="{ query: { modal: 'sumSubVerification' } }"
    />
  </div>
</template>

<script setup lang="ts">
import type { VerificationNotification } from '../../../../types'

const props = defineProps<{
  notification: VerificationNotification
}>()

const { type } = toRefs(props.notification.data)
const { t } = useI18n()

const level = computed(() => {
  switch (type.value) {
    case 'actionSourceOfFunds':
      return t('dashboard.verificationLevel', { level: 3 })
    case 'level2':
      return t('dashboard.verificationLevel', { level: 2 })
    default:
      return t('dashboard.verificationLevel', { level: 1 })
  }
})
</script>

<style scoped>
.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-xs-semibold);
    color: var(--text-primary);
  }
}

.button {
  width: 100%;
  margin-top: var(--spacing-150);
}
</style>
